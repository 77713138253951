import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import AboutJciIndia from "../../components/about/AboutJciIndia";
// import MixerAreaTwo from "../../components/MixerAreaTwo";
// import TeamArea from "../../components/TeamArea";
// import FaqArea from "../../components/FaqArea";
// import ServiceArea from "../../components/ServiceArea";
// import ClientsLogoTwo from "../../components/ClientsLogoTwo";
import Footer from "../../components/Footer";

const AboutPage = () => {
    return (
        <Layout pageTitle="JCI India Foundation | About JCI India">
            <NavOne />
            <PageHeader title="JCI India" />
            <AboutJciIndia />
            <Footer />
        </Layout>
    );
};

export default AboutPage;
