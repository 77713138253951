import React from "react";
import aboutImage2 from "../../images/jci-india.png";
import sectionIcon from "../../images/section-icon.png";
const WhyJci = () => {
  return (
    <section className="about-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="about-semi-img">
              <img src={aboutImage2} alt="" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="about-heading">
              <div className="section-heading">
                <div className="section-icon">
                  <img src={sectionIcon} alt="section-icon" />
                </div>
                <h2 className="section__title">JCI India</h2>
                {/* <p className="section__meta">why JCI</p> */}
                <p className="section__desc">
                  JCI India is a voluntary organization, membership based NGO working in India since 1949 for developing the leadership skills of young men and women of this country.
                  It is affiliated to Junior Chamber International(JCI),a worldwide federation of young leaders and entrepreneurs founded in 1944, having headquarter at Chester Field USA.</p>
                <p className="section__desc">
                  Currently it has over 200,000 active members and more than one million graduates, in over 100 countries and 6,000 communities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyJci;
